<template>
  <PublicApply :post="post"></PublicApply>
</template>
<script>
import PublicApply from './PublicApply'
export default {
  name: 'ArbitrationApply',
  data () {
    return {
      post: {
        service_type: '预约-仲裁服务',
        type: '仲裁服务机构',
        service_name: '仲裁预约办理',
        info: '' +
          '<p>1.依据《中华人民共和国仲裁法》仲裁业务主要受理平等主体的公民、法人和其他组织之间发生的合同纠纷和其他财产权益纠纷，不受理婚姻、收养、监护、扶养、继承纠纷及法律规定应当由行政机关处理的行政争议，也不受理有关职工与用人单位之间关于劳动合同、工资、报酬、福利等方面的劳动纠纷。<p>' +
          '<p>2.发生民商事纠纷的双方当事人具有有效的仲裁合同条款或事后达成仲裁协议均可向仲裁委员会申请仲裁，不受地域、级别、行业限制。</p>' +
          '<p>3.仲裁是当今国际上普遍公认并广泛采用的解决争议的主要和重要方式之一。在我国，作为解决民商事纠纷的重要途径，仲裁与诉讼具有同等法律效力。</p>' +
          '<p>4.仲裁委员会始终秉承“公平正义，持中贵和，定纷止争，和谐仲裁”的理念，坚持仲裁机构的独立性，保障当事人的意思自治，维护仲裁庭的自主裁决。</p>'
      }
    }
  },
  components: {
    PublicApply
  }
}
</script>
<style scoped >
</style>
